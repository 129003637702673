<template>
    <div class="mt-4 bg-white p-8">

        <h4>{{ $t('resorts.touristic-objects.form.mainTitle') }}</h4>
        <p class="text-sm text-dark mb-8">{{ $t('resorts.touristic-objects.form.mainSubtitle') }}</p>

        <div class="flex ml-0 flex-column w-full md:w-1/3 lg:w-1/3 pb-2">
            <vs-input v-if="isAdmin" class="border-grey-light " type="text" v-model="touristicObject.resort_reference"
                :label="$t('resorts.touristic-objects.form.resortReference')" required />

            <vs-checkbox class="ml-0 mb-4 mt-4 text-md font-bold text-dark" v-model="touristicObject.published">
                {{ $t('resorts.touristic-objects.form.published') }}
            </vs-checkbox>

            <div v-if="touristicObjectCategory === 'events'" class="flex flex-row gap-4">
                <DatePicker :label="`${$t('resorts.touristic-objects.form.startDate')} *`" v-model="startDateObject" />
                <DatePicker :label="$t('resorts.touristic-objects.form.endDate')" v-model="endDateObject" />
            </div>

            <vs-select class="border-grey-light pb-2" v-model="touristicObject.timeofyear" required
                :label="$t('timeofyear') + '*'">
                <vs-select-item :key="option.key" :value="option.key" :text="option.label | capitalize"
                    v-for="option in timeofyearOptions" />
            </vs-select>

            <selectionExpandable class="mt-4" :selections="touristicObject.selections" />

            <h6 class="mt-8"> {{ $t('resorts.touristic-objects.form.contentLanguage') }}</h6>

            <div class="w-1/2 mt-2">
                <vs-select class="border-grey-light w-full pb-3" v-model="defaultLanguage" required
                    :label="$t('resorts.touristic-objects.form.mainLanguagesInput') + '*'"
                    :placeholder="$t('resorts.touristic-objects.form.defaultLanguage')">
                    <vs-select-item v-for="option in userSpotlioLanguages" :key="option.id" :value="option.id"
                        :text="option.name" />
                </vs-select>
            </div>

            <div class="w-1/2 mt-2">
                <h6 v-if="zones || touristicObjectCategory !== 'events'" class="mb-5"> {{
            $t('resorts.touristic-objects.form.segmentation') }}</h6>

                <div v-if="touristicObjectCategory !== 'events'">
                    <vs-select :label="$t('resorts.touristic-objects.form.typeInput')" class="border-grey-light w-full"
                        v-model="touristicObjectType" :placeholder="$t('resorts.touristic-objects.form.type')"
                        @change="changeType">
                        <vs-select-item :key="option.id" :value="option.id" :text="option.name"
                            v-for="option in types" />
                    </vs-select>
                </div>

                <div v-if="zones">
                    <vs-select :label="$t('resorts.touristic-objects.form.zoneInput')" required
                        class="border-grey-light w-full" v-model="touristicObjectZone"
                        :placeholder="$t('resorts.touristic-objects.form.zone')">
                        <vs-select-item :key="option.uuid" :value="option.uuid" :text="option.name"
                            v-for="option in zones" />
                    </vs-select>
                </div>
            </div>
        </div>

        <LocalizedContent ref="localizedContent" v-if="touristicObject.default_language" v-model="localizedContent"
            :fixedLanguage="userSpotlioLanguages.find(lang => lang.id === touristicObject.default_language.id).acronym"
            :fieldsDefinition="([
            { name: 'name', label: `${$t('resorts.touristic-objects.form.name')} *`, type: 'text', maxLength: 50 },
            { name: 'highlight', label: $t('resorts.touristic-objects.form.highlight'), type: 'text', maxLength: 50 },
            { name: 'address', label: $t('resorts.touristic-objects.form.address'), type: 'text', maxLength: 50 },
            { name: 'phone', label: $t('resorts.touristic-objects.form.phone1'), type: 'text', maxLength: 20 },
            { name: 'other_phone', label: $t('resorts.touristic-objects.form.phone2'), type: 'text', maxLength: 20 },
            { name: 'email', label: $t('resorts.touristic-objects.form.email1'), type: 'text', maxLength: 100 },
            { name: 'other_email', label: $t('resorts.touristic-objects.form.email2'), type: 'text', maxLength: 100 },
            { name: 'web_link', label: $t('resorts.touristic-objects.form.website'), type: 'text', maxLength: 65535 },
            { name: 'reservation_link', label: $t('resorts.touristic-objects.form.reservationLink'), type: 'text', maxLength: 65535 },
            { name: 'rates', label: $t('resorts.touristic-objects.form.ratesTitle'), type: 'textarea', maxLength: 65535 },
            { name: 'description', label: $t('resorts.touristic-objects.form.descriptionTitle'), type: 'textarea', maxLength: 65535 },
            { name: 'description_html', label: $t('resorts.touristic-objects.form.descriptionTitle'), type: 'rich_textarea', maxLength: 65535 },
            { name: 'schedule', label: $t('resorts.touristic-objects.form.scheduleTitle'), type: 'textarea', maxLength: 65535 },
            { name: 'services_tags', label: $t('resorts.touristic-objects.form.services'), type: 'tags' },
            { name: 'equipments_tags', label: $t('resorts.touristic-objects.form.equipments'), type: 'tags' },
            { name: 'comfort_tags', label: $t('resorts.touristic-objects.form.comfort'), type: 'tags' },
            { name: 'accessibility_tags', label: $t('resorts.touristic-objects.form.accessibility'), type: 'tags' },
            { name: 'payments_mode_tags', label: $t('resorts.touristic-objects.form.payments'), type: 'tags' },
        ]).filter((localizedAttr) => isLocalizedAttributeVisible(localizedAttr.name))" />


        <div class="mt-4 flex ml-0 flex-column w-full md:w-2/3">
            <div class="flex flex-row flex-wrap w-full md:w-2/3">
                <vs-input v-if="isAttributeVisible('min_height')" class="border-grey-light pb-2 md:pr-2 w-full md:w-1/2" type="number" min="0" step="0.01"
                    :value="touristicObject.min_height" v-model="touristicObject.min_height"
                    :label="$t('resorts.touristic-objects.form.min_height') + ` (${this.$options.filters.unit_system_converter('depth', this.referenceSystem)})`" />

                <vs-input v-if="isAttributeVisible('max_height')" class="border-grey-light pb-2 w-full md:w-1/2" type="number" min="0" step="0.01"
                    :value="touristicObject.max_height" v-model="touristicObject.max_height"
                    :label="$t('resorts.touristic-objects.form.max_height') + ` (${this.$options.filters.unit_system_converter('depth', this.referenceSystem)})`" />
            </div>

            <div class="flex flex-row flex-wrap w-full md:w-2/3">
                <vs-input v-if="isAttributeVisible('start_height')" class="border-grey-light pb-2 md:pr-2 w-full md:w-1/2" type="number" min="0" step="0.01"
                    :value="touristicObject.start_height" v-model="touristicObject.start_height"
                    :label="$t('resorts.touristic-objects.form.start_height') + ` (${this.$options.filters.unit_system_converter('height', this.referenceSystem)})`" />
                <vs-input v-if="isAttributeVisible('end_height')" class="border-grey-light pb-2 w-full md:w-1/2" type="number" min="0" step="0.01"
                    :value="touristicObject.end_height" v-model="touristicObject.end_height"
                    :label="$t('resorts.touristic-objects.form.end_height') + ` (${this.$options.filters.unit_system_converter('height', this.referenceSystem)})`" />
            </div>

            <div class="flex flex-row flex-wrap w-full md:w-2/3">
                <vs-input v-if="isAttributeVisible('total_ascent')" class="border-grey-light pb-2 md:pr-2 w-full md:w-1/2" type="number" min="0" step="0.01"
                    :value="touristicObject.total_ascent" v-model="touristicObject.total_ascent"
                    :label="$t('resorts.touristic-objects.form.total_ascent') + ` (${this.$options.filters.unit_system_converter('height', this.referenceSystem)})`" />
                <vs-input v-if="isAttributeVisible('total_descent')" class="border-grey-light pb-2 w-full md:w-1/2" type="number" min="0" step="0.01"
                    :value="touristicObject.total_descent" v-model="touristicObject.total_descent"
                    :label="$t('resorts.touristic-objects.form.total_descent') + ` (${this.$options.filters.unit_system_converter('height', this.referenceSystem)})`" />
            </div>

            <div class="w-full md:w-2/3">
                <vs-input v-if="isAttributeVisible('length')" class="border-grey-light pb-2 w-full md:w-1/2" type="number" min="0" step="0.01"
                    :value="touristicObject.length" v-model="touristicObject.length"
                    :label="$t('resorts.touristic-objects.form.length') + ` (${this.$options.filters.unit_system_converter('distance', this.referenceSystem)})`" />

                <vs-input v-if="isAttributeVisible('time')" class="border-grey-light pb-2 w-full md:w-1/2" type="number" min="0"
                    :value="touristicObject.time" v-model="touristicObject.time"
                    :label="$t('resorts.touristic-objects.form.time')" />

                <vs-input v-if="isAttributeVisible('min_age')" class="border-grey-light pb-2 w-full md:w-1/2" type="number" min="0"
                    :value="touristicObject.min_age" v-model="touristicObject.min_age"
                    :label="$t('resorts.touristic-objects.form.min_age')" />

                <vs-input v-if="isAttributeVisible('tickets')" class="border-grey-light pb-2 w-full md:w-1/2" type="number" min="0"
                    :value="touristicObject.tickets" v-model="touristicObject.tickets"
                    :label="$t('resorts.touristic-objects.form.tickets')" />

                <vs-select v-if="isAttributeVisible('subtype') && subtypes.length > 0" :label="$t('resorts.touristic-objects.form.subtype')"
                    class="border-grey-light pb-2 w-full md:w-1/2" v-model="touristicObject.subtype_id" required>
                    <vs-select-item :value="null" text="-" />
                    <vs-select-item :key="option.id" :value="option.id" :text="option.name"
                        v-for="option in this.subtypes" />
                </vs-select>

                <vs-select v-if="isAttributeVisible('ride_thrill')" :label="$t('resorts.touristic-objects.form.ride_thrill')"
                    class="border-grey-light pb-2 w-full md:w-1/2" v-model="rideThrill">
                    <vs-select-item :value="null" text="-" />
                    <vs-select-item :key="option.id" :value="option.id" :text="option.key"
                        v-for="option in rideThrills" />
                </vs-select>

                <vs-select v-if="isAttributeVisible('ride_audience')" :label="$t('resorts.touristic-objects.form.ride_audience')"
                    class="border-grey-light pb-2 w-full md:w-1/2" v-model="rideAudience">
                    <vs-select-item :value="null" text="-" />
                    <vs-select-item :key="option.id" :value="option.id" :text="option.key"
                        v-for="option in rideAudiences" />
                </vs-select>

                <div v-if="isAttributeVisible('requirements')" >
                    <label class="text-sm pl-1">{{ $t('resorts.touristic-objects.form.requirements') }} </label>
                    <v-select class="border-grey-light v-selector" multiple
                        :placeholder="$t('resorts.touristic-objects.form.requirements')"
                        v-model="touristicObject.requirements" :options="requirements" label="key" />

                </div>

                <div class="w-full mt-2" v-if="isAttributeVisible('facility_difficulty')">
                    <label class="text-sm w-full pl-1">{{ this.$t("facilities.tableheader.difficulty") }} </label>
                    <v-select class="bg-white search-select rounded-lg w-full md:w-1/2"
                        :options="facilityDifficultySelect" v-model="facilityDifficulty" :appendToBody="true">
                        <template #option="option">
                            <span v-if="option.icon" class="w-8 inline-block text-center">
                                <img class="w-6" :src="option.icon" />
                            </span>
                            {{ option.label }}
                        </template>
                        <template #selected-option="option">
                            <span v-if="option.icon" class="w-8">
                                <img class="w-6" :src="option.icon" />
                            </span>
                            {{ option.label }}
                        </template>
                    </v-select>
                </div>
            </div>
        </div>

        <div class="mt-4 flex ml-0 flex-column w-full" v-if="touristicObject">
            <h4>{{ $t('resorts.touristic-objects.form.addImage') }}</h4>
            <p class="text-sm text-dark">{{ $t('resorts.touristic-objects.form.addImageSub') }}</p>

            <ImagesForm :images="touristicObject.image_list" :deletedImages="deletedImages"
                :allowedExtensions="['image/jpg', 'image/jpeg']" id="selectToImage" :validationWidth="1080"
                :validationHeight="575" :validationWeight="5500" :validationMessage="$t('banner.form.imageerror')"
                width="240" height="170" name="selectedImages"></ImagesForm>
        </div>

        <div class="mt-4 flex ml-0 flex-column w-full">
            <vs-checkbox v-model="hasCoordinates" class="my-10 checkboxform border-0 mx-0">
                {{ this.$t('resorts.touristic-objects.form.need_coords') }}
            </vs-checkbox>

            <div v-if="hasCoordinates">
                <h4>{{ this.$t('resorts.touristic-objects.form.locateMap') }}</h4>
                <p class="text-sm text-dark">{{ this.$t('resorts.touristic-objects.form.locateMapSub') }}</p>
                <MapCoordinates :defaultCoordinates="defaultCoordinates" :componentSize="mapSize"
                    @updated="handleMapChange" @invalidCoords="handleInvalidCoords" :zoom=18></MapCoordinates>
            </div>
        </div>

        <div class="flex items-center ml-0 gap-4 mt-5">
            <vs-button class="rounded-lg xs:w-full sm:w-auto ml-2 " @click="save">
                {{ $t('resorts.touristic-objects.form.saveButton') }}
            </vs-button>
            <vs-button type="border" class="rounded-lg xs:w-full sm:w-auto text-primary" @click="cancel">
                {{ $t('resorts.touristic-objects.form.cancelButton') }}
            </vs-button>
        </div>
    </div>
</template>
<script>
import { DateTime } from "luxon";
import { mapGetters } from "vuex";
import store from "@/modules/Shared/Store/store";
import loader from "@/modules/Shared/Mixins/loader";
import Tags from '@/modules/Shared/Components/form/Tags'
import DatePicker from '@/modules/Shared/Components/form/DatePicker.vue';
import { getFacilityDifficulty } from "@/modules/Resorts/Helpers/facilityHelper"
import LocalizedContent from '@/modules/Shared/Components/form/LocalizedContent'
import ImagesForm from '@/modules/Resorts/Pages/touristicObjects/forms/ImagesForm'
import { unifyPaginatedResponse } from "@/modules/Shared/Helpers/apiResponseHelper";
import TouristicObjectServices from '@/modules/Resorts/Services/TouristicObjectServices'
import MapCoordinates from '@/modules/Shared/Components/mapcoordinates/MapCoordinates.vue';
import selectionExpandable from '@/modules/Resorts/Pages/touristicObjects/components/selectionExpandable'
import mapCoordinatesExpandable from '@/modules/Resorts/Pages/touristicObjects/components/mapCoordinatesExpandable'
import {userHasAuthorization} from "@/modules/Auth/Helpers/drmHelper";

export default {
    name: 'edit-touristicObject',
    mixins: [loader],
    components: {
        ImagesForm,
        MapCoordinates,
        selectionExpandable,
        mapCoordinatesExpandable,
        DatePicker,
        LocalizedContent,
        Tags,
    },
    props: {
        create: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
        }
    },
    data() {
        return {
            referenceSystem: 'metric',
            subtypes: [],
            types: [],
            zones: [],
            rideThrills: [],
            rideAudiences: [],
            requirements: [],
            difficultyStyle: 'europe',
            deletedImages: [],
            hasCoordinates: false,
            defaultCoordinates: null,
            mapSize: { width: "w-2/3 md:w-3/3 lg:w-2/2" },
            originalLanguagesIds: [],
            touristicObject: {
                resort_reference: '',
                published: false,
                start_date: '',
                end_date: '',
                timeofyear: 'winter',
                default_language: null,
                langs_list: [],
                min_height: null,
                max_height: null,
                start_height: null,
                end_height: null,
                total_ascent: null,
                total_descent: null,
                length: null,
                time: null,
                min_age: null,
                tickets: null,
                subtype_id: null,
                map_coordinates: null,
                heights: null,
                image_list: [],
                coordinates: null,
                type: null,
                ride_thrill: null,
                ride_audience: null,
                requirements: [],
                selections: [],
            },
            timeofyearOptions: [
                { key: 'winter', label: this.$t('winter') },
                { key: 'summer', label: this.$t('summer') },
                { key: 'always', label: this.$t('always') },
            ],
        }
    },
    async beforeRouteEnter(to, from, next) {
        if (to.params.create) {
            await store.dispatch('setRouteElement', null)
            next()
            return
        }
        const touristicObject = (await TouristicObjectServices.getTouristicObject(to.params.touristicObjectUuid)).data.object
        if (!touristicObject) {
            next()
            return
        }
        await store.dispatch('setRouteElement', touristicObject)
        next(vm => { vm.touristicObject = JSON.parse(JSON.stringify(touristicObject)) })
    },
    computed: {
        ...mapGetters('resorts', ['resorts']),
        isAdmin() {
            return userHasAuthorization([{
                context: 'admin',
                drm: 'apps'
            }])
        },
        ...mapGetters(['AppActiveUser']),
        ...mapGetters(['userSpotlioLanguages']),
        DateTime() {
            return DateTime
        },
        startDateObject: {
            get() { return this.touristicObject.start_date != '' ? DateTime.fromSQL(this.touristicObject.start_date) : null },
            set(val) { this.touristicObject.start_date = val.toFormat('yyyy-MM-dd HH:mm:ss') }
        },
        endDateObject: {
            get() { return this.touristicObject.end_date != '' ? DateTime.fromSQL(this.touristicObject.end_date) : null },
            set(val) { this.touristicObject.end_date = val.toFormat('yyyy-MM-dd HH:mm:ss') }
        },
        touristicObjectType: {
            get() { return this.touristicObject.type ? this.touristicObject.type.id : null },
            set(val) { this.touristicObject.type = this.types.find(type => type.id == val) }
        },
        touristicObjectZone: {
            get() { return this.touristicObject.zone ? this.touristicObject.zone.uuid : null },
            set(val) { this.touristicObject.zone = this.zones.find(type => type.uuid == val) }
        },
        defaultLanguage: {
            get() { return this.touristicObject.default_language ? this.touristicObject.default_language.id : null },
            set(val) {
                this.touristicObject.default_language = this.userSpotlioLanguages.find(lang => lang.id == val)
                this.addDefaultLanguageToList()
            }
        },
        localizedContent: {
            get() {
                return this.touristicObject.langs_list.map((content) => {
                    return {
                        ...content,
                        address: this.touristicObject.address,
                        language: this.userSpotlioLanguages.find(lang => lang.id == content.idioma_id).acronym
                    }
                })
            },
            set(val) {
                for (const content of val) {
                    if (val.filter((c) => c.address === content.address).length === 1) {
                        this.touristicObject.address = content.address
                        break
                    }
                }

                this.touristicObject.langs_list = val.map((content) => {
                    const language = content.language
                    delete content.address;
                    delete content.language;
                    return {
                        ...content,
                        idioma_id: this.findLanguageByAcronym(language).id
                    }
                })
            }
        },
        touristicObjectCategory() { return this.$route.meta.type },
        rideThrill: {
            get() { return this.touristicObject.ride_thrill ? this.touristicObject.ride_thrill.id : null },
            set(val) { this.touristicObject.ride_thrill = this.rideThrills.find(type => type.id == val) }
        },
        rideAudience: {
            get() { return this.touristicObject.ride_audience ? this.touristicObject.ride_audience.id : null },
            set(val) { this.touristicObject.ride_audience = this.rideAudiences.find(type => type.id == val) }
        },
        facilityDifficultySelect() { return getFacilityDifficulty(this.difficultyStyle) },
        facilityDifficulty: {
            get() { return this.touristicObject.facility_difficulty ? this.facilityDifficultySelect.find((item) => item.value === this.touristicObject.facility_difficulty.name) : null },
            set(val) { this.touristicObject.facility_difficulty = { name: val.value  } }
        },
        attributePresets() {
            if (!this.types || this.touristicObject.type == null) return []
            const typeFound = this.types.find((type) => type.id == this.touristicObject.type.id)
            return typeFound ? typeFound.attributes : []
        },
        localizedAttributePresets() {
            if (!this.types || this.touristicObject.type == null) return []
            const typeFound = this.types.find((type) => type.id == this.touristicObject.type.id)
            return typeFound ? typeFound.localized_attributes : []
        },
    },
    async created() {
        await this.load(async () => {
            this.referenceSystem = this.resorts.find(resort => resort.uuid === this.$route.params.uuid).reference_system
            this.difficultyStyle = this.resorts.find(resort => resort.uuid === this.$route.params.uuid).slope_difficulty_referencesystem

            const [touristicObjectTypes, touristicObjectZones, thrills, audiences, requirements] = await Promise.all([
                TouristicObjectServices.getTouristicObjectTypes(),
                TouristicObjectServices.getTouristicObjecZones(this.$route.params.uuid),
                unifyPaginatedResponse(TouristicObjectServices.getRideThrills, { q: `resort_uuid:${this.$route.params.uuid}`, per_page: 30 }),
                unifyPaginatedResponse(TouristicObjectServices.getRideAudiences, { q: `resort_uuid:${this.$route.params.uuid}`, per_page: 30 }),
                unifyPaginatedResponse(TouristicObjectServices.getRequirements, { q: `resort_uuid:${this.$route.params.uuid}`, per_page: 30 }),
            ])
            this.zones = touristicObjectZones.data.object.length > 0 ? touristicObjectZones.data.object : null
            this.types = touristicObjectTypes.data.object
            if (this.touristicObject.category == 'event') {
                this.changeType(this.touristicObject.type.id)
            }
            this.rideThrills = thrills
            this.rideAudiences = audiences
            this.requirements = requirements
            this.touristicObject.image_list.sort((a, b) => b.main - a.main)
            this.hasCoordinates = this.touristicObject.coordinates !== null
            if (this.hasCoordinates) {
                this.defaultCoordinates = { lat: this.touristicObject.coordinates.lat, lon: this.touristicObject.coordinates.lon }
            }
            this.originalLanguagesIds = this.touristicObject.langs_list.map((l) => l.idioma_id)
            if (this.create) {
                this.touristicObject.uuid = this.$route.params.touristicObjectUuid
            }
        })
    },
    methods: {
        isAttributeVisible(attributeName) {
            if (this.attributePresets.length === 0) return true
            return this.attributePresets.includes(attributeName)
        },
        isLocalizedAttributeVisible(attributeName) {
            if (this.localizedAttributePresets.length === 0) return true
            return this.localizedAttributePresets.includes(attributeName)
        },
        addDefaultLanguageToList() {
            if (!this.findLanguageInListById(this.touristicObject.default_language.id)) {
                this.touristicObject.langs_list.push({
                    idioma_id: this.touristicObject.default_language.id
                })
            }
        },
        findLanguageInListById(id) {
            return this.touristicObject.langs_list.find((l) => l.idioma_id == id)
        },
        findLanguageByAcronym(acronym) {
            return this.userSpotlioLanguages.find((l) => l.acronym == acronym)
        },
        async changeType(newType) {
            this.subtypes = await unifyPaginatedResponse(TouristicObjectServices.getTouristicObjectSubTypes, {
                q: `type_id:${newType}`,
                per_page: 30
            })
        },
        handleMapChange(eventDataNewCoordinates) {
            this.touristicObject.coordinates = {
                lat: eventDataNewCoordinates.lat,
                lon: eventDataNewCoordinates.lon
            }
        },
        handleInvalidCoords(eventInvalidCoordinates) {
            this.$vs.notify({
                type: 'confirm',
                title: "Error",
                text: `${eventInvalidCoordinates}`,
                color: "danger",
                position: "top-right"
            });
        },
        goBackToList() {
            if (this.touristicObjectCategory == 'events') this.$router.push({ name: 'ToEvents' })
            if (this.touristicObjectCategory == 'services') this.$router.push({ name: 'ToServices' })
            if (this.touristicObjectCategory == 'facilities') this.$router.push({ name: 'ToFacilities' })
        },
        cancel() {
            window.top.postMessage('touristic-object-canceled', '*')
            this.goBackToList()
        },
        save() {
            if(this.create){
                this.createTouristicObject()
            }else{
                this.updateTouristicObject()
            }
        },
        async createTouristicObject() {
            let touristicObject = this.generateTouristicObjectPayload();
            let response = await TouristicObjectServices.createTouristicObject(this.touristicObject.uuid, touristicObject)
            if (response.status == 200 && Object.keys(response.data).length === 0) {
                this.handleTranslations()
                this.handleImages()
                window.top.postMessage('touristic-object-created', '*')
                this.goBackToList()
            }
        },

        async updateTouristicObject() {
            let touristicObject = this.generateTouristicObjectPayload();
            if (touristicObject != undefined) {
                let response = await TouristicObjectServices.updateTouristicObject(this.touristicObject.uuid, touristicObject)
                if (response.data.code == 200) {
                    this.handleTranslations()
                    this.handleImages()
                    window.top.postMessage('touristic-object-updated', '*')
                }
                this.goBackToList()
            }
        },

        validateService() {
            const { start_date, end_date, timeofyear, weight, default_language, type } = this.touristicObject;
            const errors = [];

            if (default_language == "") {
                errors.push(this.$t('resorts.touristic-objects.form.errors.defaultLanguage'));
            }
            if (timeofyear == "") {
                errors.push(this.$t('resorts.touristic-objects.form.errors.timeofyear'));
            }
            if (type == "") {
                errors.push(this.$t('resorts.touristic-objects.form.errors.type'));
            }

            if (weight === 0 || weight === '') {
                errors.push(this.$t('resorts.touristic-objects.form.errors.weight'));
            }

            if (start_date == "") {
                this.touristicObject.start_date = null
            }

            if (end_date == "") {
                this.touristicObject.end_date = null
            }

            return errors;
        },
        validateEvent() {
            const { start_date, end_date, weight, default_language } = this.touristicObject;
            const errors = [];

            if (default_language == "") {
                errors.push(this.$t('resorts.touristic-objects.form.errors.defaultLanguage'));
            }

            if (start_date == "") {
                errors.push(this.$t('resorts.touristic-objects.form.errors.start_date'));
            }

            if (weight === 0 || weight === '') {
                errors.push(this.$t('resorts.touristic-objects.form.errors.weight'));
            }
            if (end_date == "") {
                errors.push(this.$t('resorts.touristic-objects.form.errors.end_date'));

            }

            return errors;
        },

        validate() {
            let errors = []

            if (this.$route.meta.type == 'events') {
                errors = this.validateEvent(this.touristicObject)
            } else {
                errors = this.validateService(this.touristicObject)
            }

            return errors;
        },

        getCategory() {
            switch (this.touristicObjectCategory) {
                case 'services':
                    return 'service'
                case 'events':
                    return 'event'
                case 'facilities':
                    return 'facility'
            }
        },

        generateTouristicObjectPayload() {
            let errors = this.validate()

            if (errors.length > 0) {
                this.$vs.notify({
                    type: 'confirm',
                    title: "Error",
                    text: `${errors[0]}`,
                    color: "danger",
                    position: "top-right"
                });
            } else {
                if (this.touristicObject.address == null) this.touristicObject.address = ''
                let defaultLanguage = this.touristicObject.langs_list.find(lang => lang.idioma_id == this.touristicObject.default_language.id)

                const payload = {
                    "uuid": this.touristicObject.uuid,
                    "internal_identifier": this.touristicObject.uuid,
                    "name": defaultLanguage.name,
                    "category": this.getCategory(),
                    "default_language_id": this.touristicObject.default_language.id,
                    "resort_uuid": this.$route.params.uuid,
                    "published": this.touristicObject.published,
                    "weight": this.touristicObject.weight,
                    "timeofyear": this.touristicObject.timeofyear,
                    "selection": this.touristicObject.selection,
                    "selections": this.touristicObject.selections,
                    "resort_reference": this.touristicObject.resort_reference,
                    "coordinates": this.hasCoordinates ? this.touristicObject.coordinates : null,
                    ...(this.touristicObject.type != null ? { 'type_id': this.touristicObject.type.id } : null),
                    ...(this.touristicObject.zone != null ? { 'zone_uuid': this.touristicObject.zone.uuid } : null),
                    ...(this.touristicObject.address != "" ? { 'address': this.touristicObject.address } : null),
                    ...(this.touristicObject.start_date != null ? { 'start_date': this.touristicObject.start_date } : null),
                    ...(this.touristicObject.end_date != null ? { 'end_date': this.touristicObject.end_date } : null),
                    "map_coordinates": this.touristicObject.map_coordinates != null ? this.touristicObject.map_coordinates : null,
                    "heights": this.touristicObject.heights != null ? this.touristicObject.heights : null,
                    "subtype_id": this.touristicObject.subtype_id ? this.generateNumberPayloadValue(this.touristicObject.subtype_id) : null,
                    "ride_thrill_id": this.touristicObject.ride_thrill ? this.generateNumberPayloadValue(this.touristicObject.ride_thrill.id) : null,
                    "ride_audience_id": this.touristicObject.ride_audience ? this.generateNumberPayloadValue(this.touristicObject.ride_audience.id) : null,
                    "requirements_ids": this.touristicObject.requirements.length > 0 ? this.touristicObject.requirements.map((r) => r.id) : null,
                    "min_height": this.generateNumberPayloadValue(this.touristicObject.min_height),
                    "max_height": this.generateNumberPayloadValue(this.touristicObject.max_height),
                    "min_age": this.generateNumberPayloadValue(this.touristicObject.min_age),
                    "tickets": this.generateNumberPayloadValue(this.touristicObject.tickets),
                    "length": this.generateNumberPayloadValue(this.touristicObject.length),
                    "start_height": this.generateNumberPayloadValue(this.touristicObject.start_height),
                    "end_height": this.generateNumberPayloadValue(this.touristicObject.end_height),
                    "time": this.generateNumberPayloadValue(this.touristicObject.time),
                    "total_ascent": this.generateNumberPayloadValue(this.touristicObject.total_ascent),
                    "total_descent": this.generateNumberPayloadValue(this.touristicObject.total_descent),
                    ...(
                        this.touristicObject.facility_difficulty && this.getDifficultyIdentifier(this.touristicObject.facility_difficulty.name) !== null
                            ? { "facility_difficulty_id": this.getDifficultyIdentifier(this.touristicObject.facility_difficulty.name) }
                            : null),
                }

                if (this.referenceSystem === 'imperial') {
                    payload.min_height = this.$options.filters.value_system_converter(payload.min_height, 'metric', 'depth')
                    payload.max_height = this.$options.filters.value_system_converter(payload.max_height, 'metric', 'depth')
                    payload.start_height = this.$options.filters.value_system_converter(payload.start_height, 'metric', 'height')
                    payload.end_height = this.$options.filters.value_system_converter(payload.end_height, 'metric', 'height')
                    payload.total_ascent = this.$options.filters.value_system_converter(payload.total_ascent, 'metric', 'height')
                    payload.total_descent = this.$options.filters.value_system_converter(payload.total_descent, 'metric', 'height')
                    payload.length = this.$options.filters.value_system_converter(payload.length, 'metric', 'distance')
                }

                return payload
            }
        },
        generateNumberPayloadValue(value) {
            return value === '' || value === null ? null : Number(value)
        },
        getDifficultyIdentifier(difficulty)
        {
            if (this.touristicObject.type.id === 183) {
                switch (difficulty) {
                    case 'novice':
                        return 0
                    case 'easy':
                        return 1
                    case 'intermediate':
                        return 2
                    case 'advanced':
                        return 3
                    case 'expert':
                        return 4
                    case 'freeride':
                        return 5
                    case 'extreme':
                        return 6
                }
            }

            if (this.touristicObject.type.id === 186) {
                switch (difficulty) {
                    case 'easy':
                        return 7
                    case 'intermediate':
                        return 8
                    case 'advanced':
                        return 9
                    case 'expert':
                        return 10
                }
            }

            return null
        },

        handleTranslations(){
            this.originalLanguagesIds = this.originalLanguagesIds.map((id) => parseInt(id));
            this.touristicObject.langs_list.forEach(async (translation) => {
                const payload = this.generateTouristicObjectTranslationPayload(translation)
                if (this.originalLanguagesIds.includes(parseInt(translation.idioma_id))) {
                    await TouristicObjectServices.updateTouristicObjectTranslation(this.touristicObject.uuid, payload)
                }

                if (!this.originalLanguagesIds.includes(parseInt(translation.idioma_id))) {
                    await TouristicObjectServices.createTouristicObjectTranslation(this.touristicObject.uuid, payload)
                }
            })

            const originalSet = new Set(this.originalLanguagesIds)
            const currentSet = new Set(this.touristicObject.langs_list.map((l) => parseInt(l.idioma_id)))

            originalSet.difference(currentSet).forEach(async (deletedTranslationId) =>{
                await TouristicObjectServices.deleteTouristicObjectTranslation(this.touristicObject.uuid, deletedTranslationId)
            })
        },
        generateTouristicObjectTranslationPayload(translation){
            return {
                name : translation.name,
                highlight: translation.highlight,
                idioma_id : translation.idioma_id,
                web: translation.web_link !== undefined ? translation.web_link : '' ,
                phone: translation.phone !== undefined ? translation.phone : '' ,
                other_phone: translation.other_phone !== undefined ? translation.other_phone : '' ,
                email: translation.email !== undefined ? translation.email : '' ,
                other_email: translation.other_email !== undefined ? translation.other_email : '' ,
                description: translation.description !== undefined ? translation.description : '' ,
                description_html: translation.description_html !== undefined ? translation.description_html : '' ,
                reservation_link: translation.reservation_link !== undefined ? translation.reservation_link : '' ,
                rates: translation.rates != null ? translation.rates : '',
                schedule: translation.schedule != null ? translation.schedule : '',
                services_tags: translation.services_tags != null ? translation.services_tags : [],
                equipments_tags: translation.equipments_tags != null ? translation.equipments_tags : [],
                payments_mode_tags: translation.payments_mode_tags != null ? translation.payments_mode_tags : [],
                comfort_tags: translation.comfort_tags != null ? translation.comfort_tags : [],
                accessibility_tags: translation.accessibility_tags != null ? translation.accessibility_tags : [],
            }
        },
        handleImages(){
            this.touristicObject.image_list.forEach(async (image) => {
                if(image.new) {
                    await TouristicObjectServices.createTouristicObjectImage(this.touristicObject.uuid, image)
                } else {
                    await TouristicObjectServices.updateTouristicObjectImage(this.touristicObject.uuid, image)
                }
            })
            if(this.deletedImages.length > 0) {
                this.deletedImages.forEach(async (deletedImage) => {
                    await TouristicObjectServices.deleteTouristicObjectImage(this.touristicObject.uuid, deletedImage.uuid)
                })
            }
        },

    },
}
</script>
<style>
.v-selector .vs__deselect {
    display: none;
}
</style>
